<template>
  <Route
    :to="{ name: homeRouteName }"
    @click.native="handleClick"
    class="csn-nav-logo-container"
  >
    <div v-if="isCasinoPlatin">
      <img
        id="navbar-logo"
        src="@/assets/images/themes/casino-platin/logo.svg"
        alt="Casino Online"
        class="csn-nav-logo"
      />
      <!-- <PlatinCoinIcon
        id="navbar-logo"
        alt="Casino Online"
        class="csn-nav-logo csn-nav-logo-mobile csn-nav-logo-mobile-platin"
      /> -->
    </div>
    <div v-else>
      <img
        id="navbar-logo"
        src="@/assets/images/themes/casino-five/logo.svg"
        alt="Casino Online"
        class="csn-nav-logo"
      />
      <!-- <img
        id="navbar-logo"
        src="@/assets/images/themes/casino-five/club_card_logo.svg"
        alt="Casino Online"
        class="csn-nav-logo csn-nav-logo-mobile"
      /> -->
    </div>
  </Route>
</template>

<script>
import {
  RouteName,
  CASINO_PLATIN,
  NAVBAR__LOGO_BUTTON,
  EventType,
} from '@/constants'

export default {
  name: NAVBAR__LOGO_BUTTON,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    homeRouteName: () => RouteName.HOME,
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  methods: {
    handleClick() {
      this.$emit(EventType.CLICK)
    },
  },
}
</script>
